<template>
    <b-row>
        <b-col cols="12">
            <div
                class="d-sm-flex justify-content-between align-items-center mb-4"
            >
                <feather
                    type="arrow-left-circle"
                    v-b-popover.hover.top="'Presione esc'"
                    role="button"
                    class="text-secondary"
                    size="2rem"
                    @click="back"
                ></feather>
                <div class="d-flex flex-row">
                    <b-button
                        variant="secondary"
                        :disabled="isLoading"
                        v-b-popover.hover.top="'Presione esc'"
                        size="md"
                        class="mr-4"
                        @click="back"
                    >
                        Cancelar
                    </b-button>

                    <b-button
                        variant="primary"
                        :disabled="isLoading || read_only"
                        size="md"
                        v-b-popover.hover.top="
                            'Presione ctrl+shift+s o cmd+shift+s'
                        "
                        class="d-flex align-items-center"
                        @click="save"
                    >
                        <feather
                            type="save"
                            size="1rem"
                            class="mr-2 text-blue-active"
                        />
                        <span
                            v-if="isLoading"
                            class="d-sm-flex align-items-center justify-content-center"
                        >
                            <b-spinner small></b-spinner>
                            <span class="ml-2">Guardando...</span>
                        </span>
                        <span v-else>Guardar</span>
                    </b-button>
                </div>
            </div>
        </b-col>
        <b-col
            cols="12"
            lg="12"
            class="d-flex align-items-stretch"
            style="min-height: 70vh"
        >
            <b-card class="mb-4 w-100">
                <div>
                    <b-form class="p-5" v-hotkey="keymap">
                        <div class="d-flex">
                            <div>
                                <h3 class="mb-2">Datos del tercero</h3>
                                <div class="text-gray mb-5">
                                    Por favor registre los datos del tercero
                                    para continuar
                                </div>
                            </div>
                        </div>
                        <b-row>
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="visible">Tercero activo?</label>
                                    <b-form-checkbox
                                        id="visible"
                                        size="lg"
                                        switch
                                        v-model="$v.tercero.visible.$model"
                                        :value="true"
                                        :unchecked-value="false"
                                        :disabled="read_only"
                                    >
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-md-4">
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="codigo"
                                        >Tipo contribuyente
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <jautocomplete
                                        id="tipo_contribuyente"
                                        :items="tipos_contribuyente"
                                        item-text="descripcion"
                                        item-value="id"
                                        @change="setTipoDocumento"
                                        :disabled="read_only"
                                        open-on-focus
                                        v-model="
                                            $v.tercero.tipo_contribuyente.$model
                                        "
                                        :state="
                                            validateState('tipo_contribuyente')
                                        "
                                    />

                                    <!-- <b-form-select
                                        id="tipo_contribuyente"
                                        :options="tipos_contribuyente"
                                        text-field="descripcion"
                                        value-field="id"
                                        @change="setTipoDocumento"
                                        v-model="
                                            $v.tercero.tipo_contribuyente.$model
                                        "
                                        :state="
                                            validateState('tipo_contribuyente')
                                        "
                                        :disabled="read_only"
                                    >
                                        <template #first>
                                            <b-form-select-option
                                                :value="null"
                                                disabled
                                                >Seleccione</b-form-select-option
                                            >
                                        </template>
                                    </b-form-select> -->
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.tercero.tipo_contribuyente
                                                .required
                                        "
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4" lg="4" xl="4">
                                <b-form-group>
                                    <label for="tipo_documento_id"
                                        >Tipo documento
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <jautocomplete
                                        id="tipo_documento_id"
                                        :items="tipos_documento"
                                        item-text="descripcion"
                                        item-value="id"
                                        open-on-focus
                                        :disabled="
                                            disableTipoDocumento || read_only
                                        "
                                        v-model="
                                            $v.tercero.tipo_documento_id.$model
                                        "
                                        @change="
                                            ($event) =>
                                                $event === '31'
                                                    ? (tercero.digito_verificacion =
                                                          getDV(
                                                              tercero.n_documento
                                                          ))
                                                    : (tercero.digito_verificacion =
                                                          'N/A')
                                        "
                                        :state="
                                            validateState('tipo_documento_id')
                                        "
                                    />

                                    <!-- <b-form-select
                                        id="tipo_documento_id"
                                        :options="tipos_documento"
                                        text-field="descripcion"
                                        value-field="id"
                                        :disabled="
                                            disableTipoDocumento || read_only
                                        "
                                        v-model="
                                            $v.tercero.tipo_documento_id.$model
                                        "
                                        @change="
                                            ($event) =>
                                                $event === '31'
                                                    ? (tercero.digito_verificacion =
                                                          getDV(
                                                              tercero.n_documento
                                                          ))
                                                    : (tercero.digito_verificacion =
                                                          'N/A')
                                        "
                                        :state="
                                            validateState('tipo_documento_id')
                                        "
                                    >
                                        <template #first>
                                            <b-form-select-option
                                                :value="null"
                                                disabled
                                                >Seleccione</b-form-select-option
                                            >
                                        </template>
                                    </b-form-select> -->
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.tercero.tipo_documento_id
                                                .required
                                        "
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col
                                cols="2"
                                md="1"
                                lg="1"
                                xl="1"
                                v-if="tercero.tipo_documento_id === '41'"
                            >
                                <b-form-group>
                                    <label for="prefijo"
                                        >Prefijo
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-input
                                        id="prefijo"
                                        type="text"
                                        placeholder="Ej: PP"
                                        @focus="onFocused"
                                        v-model="$v.prefijo.$model"
                                        :state="validateStatePrefijo('prefijo')"
                                        :disabled="read_only"
                                    >
                                        <!-- @keydown.enter="
                                            goToNextField('n_documento')
                                        "
                                        @keydown.tab="
                                            goToNextField('n_documento')
                                        " -->
                                    </b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.prefijo.$anyError"
                                    >
                                        ({{ $v.prefijo.$model.length }} /
                                        {{ $v.prefijo.$params.maxLength.max }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.prefijo.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{ $v.prefijo.$params.maxLength.max }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.prefijo.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col
                                cols="10"
                                md="4"
                                lg="4"
                                xl="4"
                                v-if="tercero.tipo_documento_id === '41'"
                            >
                                <b-form-group>
                                    <label for="n_documento"
                                        >Pasaporte
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-input
                                        id="n_documento"
                                        type="number"
                                        placeholder="Ej: 26186920835"
                                        @focus="onFocused"
                                        v-model="$v.tercero.n_documento.$model"
                                        :state="validateState('n_documento')"
                                        :disabled="read_only"
                                    >
                                        <!-- @keydown.enter="
                                            goToNextField('nombre1')
                                        "
                                        @keydown.tab="goToNextField('nombre1')" -->
                                    </b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.tercero.n_documento.$anyError"
                                    >
                                        ({{
                                            $v.tercero.n_documento.$model.length
                                        }}
                                        /
                                        {{
                                            $v.tercero.n_documento.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.tercero.n_documento.minLength"
                                    >
                                        Este campo debe tener al menos
                                        {{
                                            $v.tercero.n_documento.$params
                                                .minLength.min
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.tercero.n_documento.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.tercero.n_documento.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.tercero.n_documento.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col
                                cols="10"
                                md="4"
                                lg="4"
                                xl="4"
                                v-if="tercero.tipo_documento_id !== '41'"
                            >
                                <b-form-group>
                                    <label for="n_documento"
                                        >Número documento
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-input
                                        id="n_documento"
                                        type="number"
                                        placeholder="Ej: 26186920835"
                                        @focus="onFocused"
                                        v-model="$v.tercero.n_documento.$model"
                                        @input="setDV"
                                        :state="validateState('n_documento')"
                                        :disabled="read_only"
                                    >
                                        <!-- @keydown.enter="
                                            goToNextField(
                                                tercero.tipo_documento_id == 31
                                                    ? 'razon_social'
                                                    : 'nombre1'
                                            )
                                        "
                                        @keydown.tab="
                                            goToNextField(
                                                tercero.tipo_documento_id == 31
                                                    ? 'razon_social'
                                                    : 'nombre1'
                                            )
                                        " -->
                                    </b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.tercero.n_documento.$anyError"
                                    >
                                        ({{
                                            $v.tercero.n_documento.$model.length
                                        }}
                                        /
                                        {{
                                            $v.tercero.n_documento.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.tercero.n_documento.minLength"
                                    >
                                        Este campo debe tener al menos
                                        {{
                                            $v.tercero.n_documento.$params
                                                .minLength.min
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.tercero.n_documento.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.tercero.n_documento.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.tercero.n_documento.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col
                                cols="2"
                                md="1"
                                lg="1"
                                xl="1"
                                v-if="tercero.tipo_documento_id !== '41'"
                            >
                                <b-form-group>
                                    <label for="digito_verificacion">DV</label>
                                    <b-form-input
                                        id="digito_verificacion"
                                        type="text"
                                        disabled
                                        v-model="
                                            $v.tercero.digito_verificacion
                                                .$model
                                        "
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row
                            class="mt-md-4"
                            v-if="tercero.tipo_documento_id !== '31'"
                        >
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="nombre1"
                                        >Primer nombre
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-input
                                        id="nombre1"
                                        type="text"
                                        placeholder="Ej: Jhon"
                                        @focus="onFocused"
                                        v-model="$v.tercero.nombre1.$model"
                                        :state="validateState('nombre1')"
                                        :disabled="read_only"
                                    ></b-form-input>
                                    <!-- @keydown.enter="
                                            goToNextField('nombre2')
                                        "
                                        @keydown.tab="goToNextField('nombre2')" -->
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.tercero.nombre1.$anyError"
                                    >
                                        ({{ $v.tercero.nombre1.$model.length }}
                                        /
                                        {{
                                            $v.tercero.nombre1.$params.maxLength
                                                .max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.tercero.nombre1.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.tercero.nombre1.$params.maxLength
                                                .max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.tercero.nombre1.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="nombre2">Segundo nombre</label>
                                    <b-form-input
                                        id="nombre2"
                                        type="text"
                                        placeholder="Ej: Jhon"
                                        @focus="onFocused"
                                        v-model="$v.tercero.nombre2.$model"
                                        :disabled="read_only"
                                        :state="validateState('nombre2')"
                                    ></b-form-input>
                                    <!-- @keydown.enter="
                                            goToNextField('apellido1')
                                        "
                                        @keydown.tab="
                                            goToNextField('apellido1')
                                        " -->
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.tercero.nombre2.$anyError"
                                    >
                                        ({{ $v.tercero.nombre2.$model.length }}
                                        /
                                        {{
                                            $v.tercero.nombre2.$params.maxLength
                                                .max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.tercero.nombre2.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.tercero.nombre2.$params.maxLength
                                                .max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="apellido1"
                                        >Primer apellido
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-input
                                        id="apellido1"
                                        type="text"
                                        placeholder="Ej: Doe"
                                        @focus="onFocused"
                                        v-model="$v.tercero.apellido1.$model"
                                        :state="validateState('apellido1')"
                                        :disabled="read_only"
                                    ></b-form-input>
                                    <!-- @keydown.enter="
                                            goToNextField('apellido2')
                                        "
                                        @keydown.tab="
                                            goToNextField('apellido2')
                                        " -->
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.tercero.apellido1.$anyError"
                                    >
                                        ({{
                                            $v.tercero.apellido1.$model.length
                                        }}
                                        /
                                        {{
                                            $v.tercero.apellido1.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.tercero.apellido1.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.tercero.apellido1.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.tercero.apellido1.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="apellido2"
                                        >Segundo apellido</label
                                    >
                                    <b-form-input
                                        id="apellido2"
                                        type="text"
                                        placeholder="Ej: Doe"
                                        @focus="onFocused"
                                        v-model="$v.tercero.apellido2.$model"
                                        :state="validateState('apellido2')"
                                        :disabled="read_only"
                                    ></b-form-input>
                                    <!-- @keydown.enter="
                                            goToNextField('telefono')
                                        "
                                        @keydown.tab="goToNextField('telefono')" -->
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.tercero.apellido2.$anyError"
                                    >
                                        ({{
                                            $v.tercero.apellido2.$model.length
                                        }}
                                        /
                                        {{
                                            $v.tercero.apellido2.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.tercero.apellido2.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.tercero.apellido2.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-md-4" v-else>
                            <b-col cols="12" md="12" lg="12" xl="12">
                                <b-form-group>
                                    <label for="razon_social"
                                        >Razón social
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-input
                                        id="razon_social"
                                        type="text"
                                        placeholder="Ej: Nombre de la empresa"
                                        @focus="onFocused"
                                        v-model="$v.tercero.razon_social.$model"
                                        :state="validateState('razon_social')"
                                        :disabled="read_only"
                                    ></b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="
                                            !$v.tercero.razon_social.$anyError
                                        "
                                    >
                                        <!-- @keydown.enter="
                                            goToNextField('telefono')
                                        "
                                        @keydown.tab="goToNextField('telefono')" -->
                                        ({{
                                            $v.tercero.razon_social.$model
                                                .length
                                        }}
                                        /
                                        {{
                                            $v.tercero.razon_social.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.tercero.razon_social.maxLength
                                        "
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.tercero.razon_social.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.tercero.razon_social.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-md-5">
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="razon_social">Teléfono</label>
                                    <b-form-input
                                        id="telefono"
                                        type="number"
                                        placeholder="Ej: 7852532"
                                        v-model="$v.tercero.telefono.$model"
                                        @focus="onFocused"
                                        :state="validateState('telefono')"
                                        :disabled="read_only"
                                    ></b-form-input>
                                    <!-- @keydown.enter="
                                            goToNextField('celular')
                                        "
                                        @keydown.tab="goToNextField('celular')" -->
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.tercero.telefono.$anyError"
                                    >
                                        ({{ $v.tercero.telefono.$model.length }}
                                        /
                                        {{
                                            $v.tercero.telefono.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.tercero.telefono.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.tercero.telefono.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="celular"
                                        >Celular
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-input
                                        id="celular"
                                        type="number"
                                        placeholder="Ej: 3154678983"
                                        v-model="$v.tercero.celular.$model"
                                        @focus="onFocused"
                                        :state="validateState('celular')"
                                        :disabled="read_only"
                                    ></b-form-input>
                                    <!-- @keydown.enter="goToNextField('email')"
                                        @keydown.tab="goToNextField('email')" -->
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.tercero.celular.$anyError"
                                    >
                                        ({{ $v.tercero.celular.$model.length }}
                                        /
                                        {{
                                            $v.tercero.celular.$params.maxLength
                                                .max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.tercero.celular.minLength"
                                    >
                                        Este campo debe tener al menos
                                        {{
                                            $v.tercero.celular.$params.minLength
                                                .min
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.tercero.celular.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.tercero.celular.$params.maxLength
                                                .max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.tercero.celular.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6" lg="6" xl="6">
                                <b-form-group>
                                    <label
                                        for="email"
                                        class="d-sm-flex align-items-center"
                                    >
                                        E-mail

                                        <small class="ml-1" style="color: red"
                                            >*</small
                                        >

                                        <feather
                                            type="help-circle"
                                            size="1rem"
                                            class="ml-2 text-blue-active"
                                            v-b-tooltip.hover.top="'Use varios emails separandolos por coma (,)'"
                                        />
                                    </label>
                                    <b-form-input
                                        id="email"
                                        type="text"
                                        placeholder="Ej: correo@ejemplo.com"
                                        v-model="$v.tercero.email.$model"
                                        @focus="onFocused"
                                        :state="validateState('email')"
                                        @input="
                                            (value) => {
                                                $v.tercero.email.$model = value
                                                    .replace(/ /g, '')
                                                    .trim()
                                            }
                                        "
                                        :disabled="read_only"
                                    ></b-form-input>
                                    <!-- @keydown.enter="
                                            goToNextField('pais_id')
                                        "
                                        @keydown.tab="goToNextField('pais_id')" -->
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.tercero.email.$anyError"
                                    >
                                        ({{ $v.tercero.email.$model.length }} /
                                        {{
                                            $v.tercero.email.$params.maxLength
                                                .max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.tercero.email.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.tercero.email.$params.maxLength
                                                .max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.tercero.email.email"
                                    >
                                        E-mail(s) inválido(s)
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.tercero.email.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-md-4">
                            <b-col cols="12" md="3">
                                <b-form-group>
                                    <label for="direccion"
                                        >Dirección
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-input
                                        autocomplete="null"
                                        id="direccion"
                                        type="text"
                                        placeholder="Ej: calle 27 #38-43"
                                        v-model="$v.tercero.direccion.$model"
                                        :state="validateState('direccion')"
                                        :disabled="read_only"
                                    ></b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.tercero.direccion.$anyError"
                                    >
                                        ({{
                                            $v.tercero.direccion.$model.length
                                        }}
                                        /
                                        {{
                                            $v.tercero.direccion.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.tercero.direccion.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.tercero.direccion.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.tercero.direccion.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3">
                                <b-form-group>
                                    <label for="pais_id"
                                        >País
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <jautocomplete
                                        id="pais_id"
                                        @focus="onFocused"
                                        :items="paises"
                                        item-text="descripcion"
                                        item-value="id"
                                        :disabled="read_only"
                                        open-on-focus
                                        v-model="$v.tercero.pais_id.$model"
                                        @change="
                                            ;(municipios = []),
                                                (tercero.municipio_id = null),
                                                getMunicipios($event)
                                        "
                                        :state="validateState('pais_id')"
                                    />

                                    <!-- <b-form-select
                                        id="pais_id"
                                        text-field="descripcion"
                                        value-field="id"
                                        :options="paises"
                                        @focus="onFocused"
                                        @keydown.enter="
                                            goToNextField('municipio_id')
                                        "
                                        @keydown.tab="
                                            goToNextField('municipio_id')
                                        "
                                        v-model="$v.tercero.pais_id.$model"
                                        @change="
                                            ;(municipios = []),
                                                (tercero.municipio_id = null),
                                                getMunicipios($event)
                                        "
                                        :state="validateState('pais_id')"
                                        :disabled="read_only"
                                    >
                                        <template #first>
                                            <b-form-select-option
                                                :value="null"
                                                disabled
                                                >Seleccione</b-form-select-option
                                            >
                                        </template>
                                    </b-form-select> -->
                                    <b-form-invalid-feedback
                                        v-if="!$v.tercero.pais_id.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3">
                                <b-form-group>
                                    <label for="municipio_id"
                                        >Ciudad
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <jautocomplete
                                        id="municipio_id"
                                        :items="municipios"
                                        item-text="descripcion"
                                        item-value="id"
                                        open-on-focus
                                        :disabled="
                                            !tercero.pais_id || read_only
                                        "
                                        v-model="$v.tercero.municipio_id.$model"
                                        :state="validateState('municipio_id')"
                                    />
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="$v.tercero.municipio_id.$error"
                                    >
                                        Campo requerido
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3">
                                <b-form-group>
                                    <label for="plazo"
                                        >Plazo (días)
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-input
                                        id="plazo"
                                        type="number"
                                        placeholder="Ej: 30"
                                        v-model="$v.tercero.plazo.$model"
                                        :state="validateState('plazo')"
                                        :disabled="read_only"
                                    >
                                    </b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.tercero.plazo.$anyError"
                                    >
                                        ({{
                                            $v.tercero.plazo.$model
                                                ? $v.tercero.plazo.$model.toString()
                                                      .length
                                                : '0'.length
                                        }}
                                        /
                                        {{
                                            $v.tercero.plazo.$params.maxLength
                                                .max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.tercero.plazo.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.tercero.plazo.$params.maxLength
                                                .max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.tercero.plazo.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-md-4">
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="cliente">Cliente</label>
                                    <b-form-checkbox
                                        id="cliente"
                                        size="lg"
                                        switch
                                        v-model="$v.tercero.cliente.$model"
                                        :value="true"
                                        :unchecked-value="false"
                                        :disabled="read_only"
                                    >
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="empleado">Empleado</label>
                                    <b-form-checkbox
                                        id="empleado"
                                        size="lg"
                                        switch
                                        v-model="$v.tercero.empleado.$model"
                                        :value="true"
                                        :unchecked-value="false"
                                        :disabled="read_only"
                                    >
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="proveedor">Proveedor</label>
                                    <b-form-checkbox
                                        id="proveedor"
                                        size="lg"
                                        switch
                                        v-model="$v.tercero.proveedor.$model"
                                        :value="true"
                                        :unchecked-value="false"
                                        :disabled="read_only"
                                    >
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="responsable_iva"
                                        >Responsable de iva</label
                                    >
                                    <b-form-checkbox
                                        id="responsable_iva"
                                        size="lg"
                                        switch
                                        v-model="
                                            $v.tercero.responsable_iva.$model
                                        "
                                        :value="true"
                                        :unchecked-value="false"
                                        :disabled="read_only"
                                    >
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-md-4">
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="gran_contribuyente"
                                        >Gran contribuyente</label
                                    >
                                    <b-form-checkbox
                                        id="gran_contribuyente"
                                        size="lg"
                                        switch
                                        v-model="
                                            $v.tercero.gran_contribuyente.$model
                                        "
                                        :value="true"
                                        :unchecked-value="false"
                                        :disabled="read_only"
                                    >
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="agente_retencion_iva"
                                        >Agente de retención de iva</label
                                    >
                                    <b-form-checkbox
                                        id="agente_retencion_iva"
                                        size="lg"
                                        switch
                                        v-model="
                                            $v.tercero.agente_retencion_iva
                                                .$model
                                        "
                                        :value="true"
                                        :unchecked-value="false"
                                        :disabled="read_only"
                                    >
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="agente_retencion_fuente"
                                        >Agente de retención en la fuente</label
                                    >
                                    <b-form-checkbox
                                        id="agente_retencion_fuente"
                                        size="lg"
                                        switch
                                        v-model="
                                            $v.tercero.agente_retencion_fuente
                                                .$model
                                        "
                                        :value="true"
                                        :unchecked-value="false"
                                        :disabled="read_only"
                                    >
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="autoretenedor_fuente"
                                        >Autoretenedor fuente</label
                                    >
                                    <b-form-checkbox
                                        id="autoretenedor_fuente"
                                        size="lg"
                                        switch
                                        v-model="
                                            $v.tercero.autoretenedor_fuente
                                                .$model
                                        "
                                        :value="true"
                                        :unchecked-value="false"
                                        :disabled="read_only"
                                    >
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-md-4">
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="regimen_simple_tributacion"
                                        >Regimen simple de tributación</label
                                    >
                                    <b-form-checkbox
                                        id="regimen_simple_tributacion"
                                        size="lg"
                                        switch
                                        v-model="
                                            $v.tercero
                                                .regimen_simple_tributacion
                                                .$model
                                        "
                                        :value="true"
                                        :unchecked-value="false"
                                        :disabled="read_only"
                                    >
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="declarante">Declarante</label>
                                    <b-form-checkbox
                                        id="declarante"
                                        size="lg"
                                        switch
                                        v-model="$v.tercero.declarante.$model"
                                        :value="true"
                                        :unchecked-value="false"
                                        :disabled="read_only"
                                    >
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                </div>
            </b-card>
        </b-col>
        <b-col cols="12">
            <div class="float-right mt-4">
                <div class="d-flex flex-row">
                    <b-button
                        variant="secondary"
                        :disabled="isLoading"
                        v-b-popover.hover.top="'Presione esc'"
                        size="md"
                        class="mr-4"
                        @click="back"
                    >
                        Cancelar
                    </b-button>

                    <b-button
                        variant="primary"
                        :disabled="isLoading || read_only"
                        size="md"
                        v-b-popover.hover.top="
                            'Presione ctrl+shift+s o cmd+shift+s'
                        "
                        class="d-flex align-items-center"
                        @click="save"
                    >
                        <feather
                            type="save"
                            size="1rem"
                            class="mr-2 text-blue-active"
                        />
                        <span
                            v-if="isLoading"
                            class="d-sm-flex align-items-center justify-content-center"
                        >
                            <b-spinner small></b-spinner>
                            <span class="ml-2">Guardando...</span>
                        </span>
                        <span v-else>Guardar</span>
                    </b-button>
                </div>
            </div>
        </b-col>
    </b-row>
</template>
<script>
import services from '@/boot/axios'
import { validationMixin } from 'vuelidate'
import {
    required,
    minLength,
    maxLength,
    // email,
    requiredIf
} from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import jautocomplete from '@/components/shared/autocomplete/jautocomplete'
import { getDV } from '@/utils/others'
import {
    removeListenerCommands,
    addListenerCommands,
    onFocused,
    goToNextField,
    compareObjects,
    emailRule
} from '@/utils/others'

const { API } = services

const defaultTercero = () =>
    JSON.parse(
        JSON.stringify({
            tipo_contribuyente: null,
            tipo_documento_id: null,
            n_documento: '',
            digito_verificacion: '0',
            nombre1: '',
            nombre2: '',
            apellido1: '',
            apellido2: '',
            razon_social: '',
            telefono: '',
            celular: '',
            direccion: '',
            email: '',
            empresa_id: null,
            municipio_id: null,
            visible: true,
            agente_retencion_fuente: false,
            agente_retencion_iva: false,
            autoretenedor_fuente: false,
            regimen_simple_tributacion: false,
            responsable_iva: false,
            gran_contribuyente: false,
            declarante: false,
            cliente: false,
            empleado: false,
            proveedor: false,
            pais_id: null,
            plazo: 0
        })
    )

export default {
    name: 'CrearTercero',
    mixins: [validationMixin],
    components: { jautocomplete },
    data: () => ({
        getDV,
        read_only: false,
        terceroCopy: defaultTercero(),
        tercero: defaultTercero(),
        isLoading: false,
        isSaved: true,
        paises: [],
        municipios: [],
        tipos_documento: [],
        tipos_contribuyente: [],
        disableTipoDocumento: false,
        prefijo: ''
    }),
    watch: {
        tercero: {
            handler(newValue) {
                this.isSaved = compareObjects(this.terceroCopy, newValue)
            },
            deep: true
        }
    },
    mounted() {
        this.goToNextField('tipo_contribuyente')
        addListenerCommands(this)
        this.getParams()
        this.getTiposDocumento()
        this.getTiposContribuyente()
        this.getPaises()
        // this.loadData()
    },
    beforeDestroy() {
        removeListenerCommands(this)
    },
    beforeRouteLeave(to, from, next) {
        if (this.isSaved) {
            next()
        } else {
            this.$bvModal
                .msgBoxConfirm(
                    `
        Este formulario contiene información que no ha sido guardada,
        si continua con esta acción no se guardarán los cambios, desea continuar con esta acción?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        next()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    validations: {
        prefijo: {
            required: requiredIf((model) => {
                return model.tercero.tipo_documento_id === '41'
            }),
            maxLength: maxLength(2)
        },
        tercero: {
            tipo_contribuyente: {
                required
            },
            tipo_documento_id: {
                required
            },
            n_documento: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(20)
            },
            digito_verificacion: {},
            nombre1: {
                required: requiredIf((model) => {
                    return model.tipo_documento_id !== '31'
                }),
                maxLength: maxLength(30)
            },
            pais_id: {
                required
            },
            plazo: {
                required: (currentValue) => {
                    // console.log(currentValue)
                    const value =
                        currentValue !== null &&
                        currentValue !== undefined &&
                        currentValue !== ''
                    return value
                },
                maxLength: maxLength(4)
            },
            nombre2: {
                maxLength: maxLength(30)
            },
            apellido1: {
                required: requiredIf((model) => {
                    return model.tipo_documento_id !== '31'
                }),
                maxLength: maxLength(30)
            },
            apellido2: {
                maxLength: maxLength(30)
            },
            razon_social: {
                required: requiredIf((model) => {
                    return model.tipo_documento_id === '31'
                }),
                maxLength: maxLength(150)
            },
            telefono: {
                required,
                maxLength: maxLength(15)
            },
            celular: {
                required,
                minLength: minLength(7),
                maxLength: maxLength(15)
            },
            email: {
                required,
                email: function (value) {
                    const emailsArray = value
                        .split(',')
                        .filter((email) => email)

                    const validEmails = emailsArray.map((email) => {
                        const pureEmail = email.replace(/ /g, '')
                        return emailRule(pureEmail)
                    })

                    return !validEmails.some(
                        (emailValidation) => emailValidation === false
                    )
                },
                maxLength: maxLength(150)
            },
            direccion: {
                required,
                maxLength: maxLength(150)
            },
            visible: {},
            cliente: {},
            empleado: {},
            proveedor: {},
            declarante: {},
            gran_contribuyente: {},
            agente_retencion_fuente: {},
            agente_retencion_iva: {},
            autoretenedor_fuente: {},
            regimen_simple_tributacion: {},
            responsable_iva: {},
            municipio_id: {
                required
            }
        }
    },
    methods: {
        onFocused,
        goToNextField,
        showLog(a) {
            console.log(a)
        },
        setDV() {
            let DV = getDV(this.tercero.n_documento)
            this.tercero.digito_verificacion = DV
        },
        getMunicipios(pais_id) {
            API.POST({
                url: 'general/municipio/query',
                data: {
                    p_datajson: {
                        pais_id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    // console.log(dato);
                    if (status === 'ok') {
                        this.municipios = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getPaises() {
            API.POST({
                url: 'general/pais/query',
                data: {
                    p_datajson: {},
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    // console.log(dato);
                    if (status === 'ok') {
                        this.paises = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getTiposContribuyente() {
            API.POST({
                url: 'general/query',
                data: {
                    p_datajson: {},
                    p_opc: 'GET_ALL_TIPO_EMPRESA',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.tipos_contribuyente = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getTiposDocumento() {
            API.POST({
                url: 'general/query',
                data: {
                    p_datajson: {},
                    p_opc: 'GET_ALL_TIPO_DOCUMENTO',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    // console.log(response.data);
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.tipos_documento = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        setTipoDocumento(id) {
            if (id === 2) {
                this.$v.tercero.tipo_documento_id.$model = '31'
                this.disableTipoDocumento = true
                this.tercero.nombre1 = ''
                this.$v.tercero.nombre1.$reset()
                this.tercero.nombre2 = ''
                this.tercero.apellido1 = ''
                this.$v.tercero.apellido1.$reset()
                this.tercero.apellido2 = ''
            } else {
                this.$v.tercero.tipo_documento_id.$model = '13'
                this.disableTipoDocumento = false
                this.tercero.razon_social = ''
                this.$v.tercero.razon_social.$reset()
            }
        },
        getParams() {
            const { tercero_id } = this.$route.params
            if (tercero_id) {
                this.getTerceroById(tercero_id)
            }

            this.read_only = this.$route.meta.read_only
        },
        getTerceroById(id) {
            API.POST({
                url: 'general/tercero/query',
                data: {
                    p_datajson: {
                        id
                    },
                    p_opc: 'FIND_BY_ID',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        console.log(dato)
                        this.getMunicipios(dato.pais_id)
                        this.terceroCopy = { ...dato }
                        this.tercero = { ...dato }
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        save() {
            const valid = !this.$v.tercero.$invalid
            const validP = !this.$v.prefijo.$invalid
            if (valid && validP) {
                this.tercero.empresa_id = this.getUltimaEmpresa.id
                this.isLoading = true
                const p_opc = this.tercero.id ? 'UPDATE' : 'INSERT'
                API.POST({
                    url: 'general/tercero/crud',
                    data: {
                        p_datajson: {
                            ...this.tercero
                        },
                        p_opc,
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        this.isLoading = false
                        if (status === 'ok') {
                            this.isSaved = true
                            if (this.tercero.cliente && p_opc === 'INSERT') {
                                this.promptCreateEstablecimientoComercio(dato)
                            } else {
                                this.back()
                            }
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            } else {
                this.$v.tercero.$touch()
                if (this.tercero.tipo_documento_id === '41') {
                    this.$v.prefijo.$touch()
                }
            }
        },
        promptCreateEstablecimientoComercio(tercero) {
            this.$bvModal
                .msgBoxConfirm(
                    `Desea crear el establecimiento de comercio para este tercero?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then(async (value) => {
                    if (value) {
                        await this.$router.push({
                            name: 'Crear establecimiento comercio',
                            params: { tercero }
                        })
                    } else {
                        this.back()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        back() {
            this.$router.back()
        },
        validateState(key) {
            const { $dirty, $error } = this.$v.tercero[key]
            return $dirty ? !$error : null
        },
        validateStatePrefijo() {
            const { $dirty, $error } = this.$v.prefijo
            return $dirty ? !$error : null
        }
    },
    computed: {
        keymap() {
            if (
                navigator.platform.toLowerCase().includes('mac') ||
                navigator.platform.toLowerCase().includes('macintosh')
            ) {
                return {
                    'command+shift+s': this.read_only ? () => {} : this.save,
                    esc: this.back
                }
            } else if (
                navigator.platform.toLowerCase().includes('win') ||
                navigator.platform.toLowerCase().includes('windows')
            ) {
                return {
                    'ctrl+shift+s': this.read_only ? () => {} : this.save,
                    esc: this.back
                }
            } else {
                return {}
            }
        },
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 1 }
            return json
        },
        ...mapGetters('usuario', ['getUserData', 'getAuditoriaJson']),
        ...mapGetters('empresas', ['getUltimaEmpresa'])
    }
}
</script>

<style></style>
